<template>
    <v-dialog
        v-model="showDialog"
        max-width="700px"
        :scrollable="false"
        content-class="elevation-0"
        opacity="0.8"
    >
        <v-card
            style="border-radius: 16px; max-height: 95vh;"
        >
            <v-card-title
                class="d-flex justify-space-between"
                style="background-color: var(--v-headerblue-base)"
            >
                <p
                    :style="`color: white; ${windowWidth <= 600 ? 'font-size: 15px;' : ''}`"
                >
                    {{ headerText }}
                </p>
                <v-btn
                    icon
                    color="transparent"
                    elevation="0"
                    x-small
                    style="width: 30px; min-width: 30px; height: 30px"
                    @click="$emit('close')"
                >
                    <img
                        class="iconToWhite icon30"
                        alt="Abbrechen"
                        :src="cancelIcon"
                    >
                </v-btn>
            </v-card-title>
            <p
                class="mb-0 px-7"
                style="text-align: left; padding-top: 8px;"
            >
                {{ descriptionText }}
            </p>
            <v-card-text
                class="d-inline-flex flex-column"
                style="padding-top: 8px"
            >
                <div class="d-flex flex-row">
                    <div
                        :class="windowWidth > 600 ? 'halfTheExportPopup' : 'wholeExportPopup'"
                    >
                        <div
                            data-simplebar
                            class=" scroll-area"
                        >
                            <v-checkbox
                                v-if="mode === 'diary'"
                                v-model="exportDiary"
                                label="Klassenbucheinträge einfügen"
                                dense
                                hide-details
                            />
                            <v-checkbox
                                v-if="mode === 'diary' "
                                v-model="exportLateTimes"
                                label="Fehltage einfügen"
                                class="mb-1"
                                dense
                                hide-details
                            />
                            <v-checkbox
                                v-if="mode === 'diary' "
                                v-model="exportPupilDiaryNotes"
                                label="einzelne Bemerkungen einfügen"
                                dense
                                hide-details
                            />
                            <div
                                v-if="mode === 'diary'"
                                class="d-flex flex-row align-center"
                            >
                                <v-checkbox
                                    v-model="exportAppointmentDiaryHistories"
                                    :disabled="!exportDiary"
                                    label="Klassenbucheintrag - Historie einfügen"
                                    dense
                                    hide-details
                                />
                                <v-tooltip
                                    top
                                    nudge-right="100"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <img
                                            :src="infoIcon"
                                            style="height: 20px;"
                                            alt
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                    </template>
                                    <p
                                        style="max-width: 200px"
                                    >
                                        Fügt Verlauf der Bearbeitungen der Klassenbucheinträge als Anhang bei. 
                                    </p>
                                </v-tooltip>
                            </div>
                            <v-date-picker
                                v-model="dates"
                                range
                                :allowed-dates="weekdaysOnly"
                                color="headerblue"
                                :first-day-of-week="1"
                                locale="de-de"
                                :selected-items-text="multipleDatesSelectedText"
                            />
                        </div>
                        <v-btn
                            color="success"
                            class="text-capitalize mt-5"
                            :loading="loadingButton"
                            @click="exportDocument"
                        >
                            Export
                        </v-btn>
                    </div>
                    <div
                        v-if="windowWidth > 600"
                        class="otherHalfOfTheExportPopup"
                    >
                        <img
                            :src="bigPictureIcon"
                            width="100px"
                            height="100px"
                            class="mx-auto"
                        >
                        <p class="mx-auto">
                            Vorschau in Entwicklung
                        </p>
                    </div>
                </div>
                <div id="disclaimer">
                    <p />
                </div>
            </v-card-text>
        </v-card>
        <v-overlay
            v-if="showExportingOverlay"
            opacity="0.8"
            style="height: 100vh; z-index:100!important"
        >
            <div
                v-if="showExportingOverlay"

                class="d-flex flex-column justify-space-around align-center"
            >
                <v-progress-circular
                    indeterminate
                    size="50"
                />
                <p>
                    PDF-Export wird vorbereitet, bitte gedulden Sie sich einen Moment
                </p>
            </div>
        </v-overlay>
    </v-dialog>
</template>

<script>
import bigPictureIcon from "@/assets/Icons/EditorRedesign/bild.svg";
import currentLogo from "@/assets/login-logo.png";
import cancelIcon from "@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg";
import infoIcon from '@/assets/Icons/FaceliftIcons/info_hinweis.svg';
import {mapMutations, mapState} from "vuex";

export default {
    name: "DiaryExportDialog",
    props: {
        open: { required: true, type: Boolean },
        currentGroup: { required: true, type: String },
        exportDataFunction: { required: true, type: Function },
        mode: { required: false, type: String, default: 'diary' },
        accountRole:{ required: false, type: String, default: 'maintainer'},
        showExportingOverlay:{ required: false, type: Boolean},
    },
    data: () => ({
        dates: [],
        loadingButton: false,
        exportDiary: true,
        exportLateTimes: false,
        exportPupilDiaryNotes: false,
        bigPictureIcon,
        exportAppointmentDiaryHistories: false,
        infoIcon,
        // normale icons
        currentLogo,
        cancelIcon
    }),
    computed: {
        ...mapState('util', ['windowWidth']),
        showDialog() {
            return this.open;
        },
        // UI dependend on mode
        headerText() {
            if (this.mode === 'latetimes') {
                return 'Fehlzeiten als PDF exportieren'
            }
            return 'Klassenbuch als PDF exportieren'
        },
        descriptionText() {
            if (this.mode === 'latetimes') {
                return 'Wählen Sie ein Start- und ein Enddatum aus zwischen welchen die Fehlzeiten des SuS als PDF exportiert werden sollen.'
            }
            return 'Wählen Sie ein Start- und ein Enddatum aus zwischen welchen die Klassenbucheinträge als PDF exportiert werden sollen.'
        },
        sortedDates() {
            const sortedDates = this.dates;
            sortedDates.sort((a,b) => {
                return a < b ? -1 : (a > b ? 1 : 0);
            });
            return sortedDates;
        },
        multipleDatesSelectedText() {
            if (this.dates.length === 2) {
                const fromDate = new Date(this.sortedDates[0]);
                const fromString = fromDate.toLocaleDateString('de-DE', { day: 'numeric', month: 'numeric', });
                const toDate = new Date(this.sortedDates[1]);
                const toString = toDate.toLocaleDateString('de-DE', { day: 'numeric', month: 'numeric', });
                return `${fromString}-${toString}`;
            }
            return 'Ausgewählt';
        },
    },
     async mounted() {
        const today = new Date().toISOString().slice(0, 10);
        this.dates = [today, today];
     },
    methods: {
        ...mapMutations('snackbar', ['showSnackbar']),
        weekdaysOnly(val) {
            let date = new Date(val);
            //saturday is weekday 0, sunday is weekday 6, so monday-friday is 1-5
            return date.getDay() > 0 && date.getDay() < 6;
        },
        async exportDocument() {
            try {
                console.log('exportDocument for mode', this.mode)
                this.loadingButton = true;
                // get start and end dates from sorted dates computed
                const startDateRaw = new Date(this.sortedDates[0]);
                const endDateRaw = new Date(this.sortedDates[1]);
                let startDate = startDateRaw;
                let endDate = endDateRaw;
                // set start date to day 1 of corresponding week
                if (startDateRaw.getDay() > 1) {
                    startDate.setDate(new Date(startDateRaw).getDate() - (startDateRaw.getDay() - 1));
                }
                // set end date to day 5 of corresponding week
                if (endDateRaw.getDay() < 5) {
                    endDate.setDate(new Date(endDateRaw).getDate() + (5 - endDateRaw.getDay()));
                }
                // call export function for mode
                if (this.mode === 'diary') {
                    this.exportDataFunction(this.currentGroup, this.exportDiary, this.exportLateTimes, this.exportPupilDiaryNotes, this.exportAppointmentDiaryHistories, startDate, endDate)
                } else if (this.mode === 'latetimes') {
                    this.exportDataFunction(startDate, endDate)
                }
            } catch (e) {
                console.error(e);
                this.showSnackbar({ message: 'Leider ist ein Fehler aufgetreten.', color: 'error' });
                this.loadingButton = false;
            }
        },

        finishExportSuccessfully() {
            // after export is complete:
            this.loadingButton = false;
            // show snackbar
            this.showSnackbar({ message: 'Klassenbuch erfolgreich exportiert!', color: 'success' });
            // close
            this.$emit('close');
        },
    },
}
</script>

<style scoped>
.halfTheExportPopup {
    width: 60%;
    display: flex;
    align-content: flex-start;
    flex-flow: column;
    padding-inline: 5px;
}

.wholeExportPopup {
    width: 100%;
    display: flex;
    align-content: flex-start;
    flex-flow: column;
    padding-inline: 5px;
}

.otherHalfOfTheExportPopup {
    width: 50%;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-flow: column;
    background-color: lightgray;
}

#disclaimer {
    padding: 5px 5px 0 5px;
    width: 100%;
}
.scroll-area{
    margin: auto;
    height: auto;
    max-height: 60vh;
    min-width:290px;
    border-radius: 8px 8px 8px 8px;
    overflow-x: hidden
}
</style>
